/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_25_138 {
  border-color: var(--default-background) var(--default-background)
    var(--default-background) transparent;
}

.clt_25_83 {
  font-family: 'Open Sauce Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;

  &:global(.small) {
    font-size: 12px;
  }

  &:global(.medium) {
    font-size: 16px;
  }

  &:global(.big) {
    font-size: 20px;
  }
}

.clt_25_82 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 4px;
  width: 100%;
  background: var(--default-info);
  color: var(--default-background);

  &:global(.rounded) {
    border-radius: 8px;
  }

  &:global(.info) {
    background: var(--default-info);

    &:global(:not(.disabled):not(.loading):hover) {
      background: var(--default-text-title);
    }
  }

  &:global(.disabled) {
    background: var(--default-background-disabled);
    cursor: default;

    & > span {
      color: var(--default-text-disabled);
    }
  }

  &:global(.hint) {
    background: var(--default-background-hint);
    color: #070d39;

    &:global(:not(.disabled):not(.loading):hover) {
      background: var(--default-background-disabled);
    }
  }
}
