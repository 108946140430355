/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_60_13 {
  padding: 0px 24px;
  color: var(--default-text-title);
}

.clt_60_12 {
  width: 96vw;
  max-width: 970px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
