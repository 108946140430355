/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_v200_102805 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 100%;
  background: var(--default-background-hint);
  border-radius: 8px;
  font-family: 'Open Sauce One';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
}

:global(.input-error-state) {
  outline: 1px solid var(--negative-default);
  border: unset;
}
