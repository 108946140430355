/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_v200_102907 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_54_64 {
  border-radius: 4px;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.clt_53_315 {
  color: var(--default-text-title);

  @media only screen and (max-width: 600px) {
    margin-top: 24px;
  }
}

.clt_54_93 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_54_92 {
  width: fit-content;
}

.clt_54_95 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_54_96 {
  justify-content: center;
  display: flex;
  align-items: center;
}

.clt_54_94 {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 4px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  color: rgb(89, 89, 89);
  font-family: 'Open Sauce Sans';
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  max-width: 400px;
  text-align: justify;
}

.clt_54_349 {
  margin-top: 4px;
  width: fit-content;
}

.clt_54_83 {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  flex: 1;

  & > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: var(--default-text-body);
  }
}

.clt_53_314 {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.clt_v200_102927 {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    background: #f5f5f5;
    border-radius: 4px;
    row-gap: 16px;

    > p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: var(--default-text-body);
      font-style: normal;
      text-align: justify;
    }

    > div {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: var(--default-text-title);
      }

      svg {
        fill: var(--default-text-title);
      }
    }
  }
}

.clt_v200_103043 {
  & > span {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 18px;
  }
}

.clt_63_268 {
  & svg {
    fill: var(--default-info);
  }
}

.clt_v200_98346 {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 4px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  color: rgb(89, 89, 89);
  font-family: 'Open Sauce Sans';
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  max-width: 400px;
  text-align: justify;
  z-index: 2;
}

.clt_53_278 {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
