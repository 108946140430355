/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_24_42 {
  width: 40px;
  height: 40px;
  background: var(--default-background-hint);
}

.clt_24_72 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_24_71 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_24_70 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_24_73 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.clt_24_69 {
  width: 140px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  transform: rotate(-45deg);
  height: fit-content;
}

.clt_24_167 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.clt_24_145 {
  width: 58%;
  max-width: 1550px;
}

.clt_24_140 {
  width: 40px;
  height: 40px;
  background: var(--default-background-hint);
}

.clt_24_139 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_24_142 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_24_143 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_24_141 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.clt_24_138 {
  width: 140px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  transform: rotate(45deg);
  height: fit-content;
}

.clt_24_181 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.clt_24_16 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  z-index: -1;
}

.clt_v200_102732 {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.clt_13_95 {
  color: var(--default-text-title);
  text-align: center;
}

.clt_v200_102741 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_17_270 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_17_273 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_28_453 {
  text-align: center;
  padding: 0px 24px;
}

.clt_28_471 {
  text-align: center;
  color: var(--default-text-body);
  margin-bottom: 8px;
}

.clt_28_452 {
  max-width: 490px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.clt_28_437 {
  & :global(.genClass42) {
    max-width: 490px;
    width: 100vw;
  }
}

.clt_14_65 {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;

  & > * {
    flex: 1;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
}

.clt_v200_102717 {
  color: var(--default-text-title);
  text-align: center;
}

.clt_48_36 {
  color: var(--default-text-title);
  text-align: center;
}

.clt_v200_102868 {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  width: 100%;
}

.clt_48_93 {
  margin-top: 80px;
  width: 100%;
}

.clt_13_78 {
  width: 58%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  max-width: 1550px;
}

.clt_14_94 {
  margin-top: 24px;
}

.clt_24_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.clt_v200_102674 {
  padding: 40px 80px 80px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 80px;
}

.clt_17_192 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
