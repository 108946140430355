/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_96_266 {
  margin-bottom: 46px;
}

.clt_96_121 {
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #262626;
}

.clt_96_122 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #262626;
}

.clt_96_173 {
  color: #595959;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;

  & a {
    text-decoration: underline;
    color: var(--default-info);
  }
}

.clt_96_149 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  row-gap: 16px;
}

.clt_96_115 {
  background-color: var(--default-background);
  height: 100vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
