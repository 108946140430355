/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_v200_103153 {
  height: auto;
  width: 50%;
}

.clt_199_46 {
  all: unset;
  margin-top: 12px;
  cursor: pointer;
  font-weight: 500;
  color: var(--default-info);
  letter-spacing: -0.02em;
}

.clt_69_78 {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  white-space: break-spaces;

  & > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.05em;
    color: var(--default-text-body);
  }

  & > h1 {
    color: var(--default-text-title);
  }
}

.clt_70_142 {
  & svg {
    fill: var(--default-text-body);
  }
}

.clt_v200_103217 {
  all: unset;
  cursor: pointer;
}

.clt_70_148 {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  align-items: center;
  width: 100%;
  max-width: 774px;
}

.clt_69_51 {
  display: flex;
  padding: 40px 40px 72px 40px;
  flex-direction: column;
  row-gap: 80px;
  align-items: center;
  background: var(--default-background-hint);
  text-align: center;
}
