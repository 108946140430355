/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_v200_102852 {
  text-align: center;
  width: 80%;
}

.clt_46_13 {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: var(--default-background);
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
  }
}

.clt_v200_102761 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_14_129 {
  border-radius: 8px;
  width: 275px;
  height: 275px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.clt_14_147 {
  color: var(--default-text-title);
}

.clt_14_172 {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
  row-gap: 8px;
  margin-bottom: 4px;
  flex: 1;
}

.clt_19_1 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_14_439 {
  width: fit-content;
}

.clt_17_295 {
  text-align: justify;
  color: var(--default-text-body);
}

.clt_20_128 {
  justify-content: center;
  display: flex;
  align-items: center;
}

.clt_17_137 {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 4px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  color: rgb(89, 89, 89);
  font-family: 'Open Sauce Sans';
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  max-width: 400px;
  text-align: justify;
}

.clt_53_103 {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  flex: 1;
}

.clt_52_34 {
  all: unset;
  padding: 24px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  cursor: pointer;
  color: var(--default-info);
  justify-content: center;
  width: -webkit-fill-available;

  & svg {
    fill: var(--default-info);
  }

  &:hover {
    color: var(--default-background);
    background-color: var(--default-info);

    & svg {
      fill: var(--default-background);
    }
  }

  &:global(.disabled) {
    background-color: var(--default-background-disabled);
    cursor: default;
    color: var(--default-text-disabled);

    & svg {
      fill: var(--default-text-disabled);
    }
  }
}

.clt_63_282 {
  & svg {
    fill: var(--default-info);
  }
}

.clt_v200_103088 {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 4px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  color: rgb(89, 89, 89);
  font-family: 'Open Sauce Sans';
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  max-width: 400px;
  text-align: justify;
  z-index: 2;
}

.clt_52_33 {
  width: 100%;
  display: flex;
  border-top: 1px solid var(--default-background-disabled);

  & > div:not([role='tooltip']) + div:not([role='tooltip']) {
    border-left: 1px solid var(--default-background-disabled);
  }

  & > div:not([role='tooltip']) {
    flex: 1 1 0;
    width: 0;
  }
}

.clt_14_102 {
  background: var(--default-background);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 307px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  &:hover {
    outline: 2px solid var(--default-info);

    & > div:first-child > div:first-child > div:first-child {
      opacity: 1;
      visibility: unset;
    }
  }
}
