/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_13_183 {
  color: var(--default-text-title);
}

.clt_13_184 {
  color: var(--default-info);
}

.clt_13_165 {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.05em;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
}
