/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_27_47 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  content: '';
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  visibility: hidden;

  &:global(.open) {
    opacity: 1;
    visibility: unset;
  }
}
