/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

html,
body {
  height: 100%;
  background-color: #ffffff;
}

#root {
  min-height: 100%;
  height: 1px;
  overflow-y: auto;
}
