/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_85_2 {
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: var(--default-text-title);
}

.clt_85_35 {
  & svg {
    fill: var(--default-text-title);
  }

  &:global(.open svg) {
    transform: rotate(180deg);
  }
}

.clt_85_1 {
  display: flex;
  cursor: pointer;
  padding: 16px 16px 8px 16px;
}

.clt_85_114 {
  padding: 0px 16px 16px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
  white-space: break-spaces;
}
