/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_48_40 {
  width: 100%;
  display: flex;

  & > button {
    flex: 1;
  }

  & > button + button {
    border-left: unset !important;
  }

  & > button:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & > button:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.clt_48_130 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.clt_v200_101085 {
  all: unset;
  background: var(--default-background);
  padding: 12px;
  border: 2px solid var(--default-text-body);
  width: 100%;
  text-align: center;
  cursor: pointer;

  & > span {
    color: var(--default-text-body);
  }

  &:global(.active) {
    background: var(--default-info);
    cursor: default;
    border: 2px solid var(--default-info);

    & > span {
      color: var(--default-background);
    }
  }

  &:hover {
    background: var(--default-text-title);

    & > span {
      color: var(--default-background);
    }
  }
}
