/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_111_2 {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--default-background-disabled);
  padding: 16px;
  gap: 8px;

  & :global(span.status-tag) {
    padding: 4px;
    color: var(--default-background);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    border-radius: 8px;
    height: fit-content;
  }

  &:global(.no-image) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.clt_v200_103588 {
  height: 24px;
}

.clt_v200_103321 {
  background: #45854b;
}

.clt_v200_103337 {
  background: #c14747;
}

.clt_111_16 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clt_99_591 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: var(--default-text-title);
}

.clt_99_582 {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1;
  justify-content: space-between;
}

.clt_104_825 {
  &:global(.disabled) {
    & > p {
      display: none;
    }
  }

  & > p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: var(--default-text-body);

    & :global(.highlight) {
      color: #1355ff;
    }
  }
}

.clt_99_790 {
  height: 12px;
  width: 100%;
  -webkit-appearance: none;

  &::-webkit-progress-bar {
    background-color: #f7f9fc;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &::-webkit-progress-value {
    background-color: #1355ff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &[value='8'] {
    &::-webkit-progress-value {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &:global(.disabled) {
    &::-webkit-progress-value {
      background-color: #67718e;
    }
  }
}

.clt_99_713 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #8c8c8c;
}

.clt_99_714 {
  all: unset;
  cursor: pointer;
  align-self: flex-end;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #1355ff;

  &:global(.disabled) {
    color: #8c8c8c;
    cursor: not-allowed;
  }
}

.clt_99_699 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clt_99_686 {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.clt_v200_103304 {
  padding: 16px;
  border-top: 1px solid var(--default-background-disabled);
}

.clt_98_248 {
  background: var(--default-background);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
