/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_v200_103595 {
  height: 28px;
}

.clt_102_271 {
  color: var(--default-text-title);
}

.clt_102_305 {
  color: #1355ff;
}

.clt_102_308 {
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
}

.clt_v200_103524 {
  border-left: 2px solid #1355ff;
  height: calc(50% + 16px);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -16px;

  &:global(.locked) {
    border-left: 2px solid var(--default-background-disabled);
  }
}

.clt_105_25 {
  width: 12px;
  height: 12px;
  background: #1355ff;
  border: 2px solid #1355ff;
  border-radius: 50%;
  z-index: 1;

  &:global(.locked) {
    background: var(--default-background);
    border: 2px solid var(--default-background-disabled);
  }
}

.clt_v200_103547 {
  border-left: 2px solid #1355ff;
  height: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50%;

  &:global(.locked) {
    border-left: 2px solid var(--default-background-disabled);
  }
}

.clt_105_0 {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 16px;
}

.clt_102_396 {
  background: var(--default-background-hint);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  &:global(.locked) {
    background: var(--default-background-disabled);
    cursor: not-allowed;
  }

  &:global(.empty) {
    cursor: default;
  }
}

.clt_102_397 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: var(--default-text-title);
  text-align: start;

  &:global(.locked) {
    color: var(--default-text-disabled);
  }
}

.clt_v200_103410 {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--default-background);
  font-weight: 500;
  background: #1355ff;
  border-radius: 8px;
  padding: 4px 8px;
}

.clt_v200_103570 {
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #8c8c8c;
}

.clt_v200_103433 {
  display: flex;

  & svg {
    fill: var(--default-text-body);
    transition: transform 0s;
  }

  &:global(.opened) {
    & svg {
      transform: rotate(180deg);
    }
  }

  &:global(.locked) {
    & svg {
      fill: var(--default-text-disabled);
    }
  }
}

.clt_102_494 {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;
  justify-content: center;
}

.clt_v200_103461 {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0px 16px 16px 16px;
  background: var(--default-background-hint);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & > p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: var(--default-text-body);
    text-align: start;

    & :global(.highlight) {
      color: #1355ff;
    }
  }
}

.clt_102_395 {
  flex: 1;
}

.clt_v200_103661 {
  display: flex;
}

.clt_102_348 {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow-y: auto;
}

.clt_102_266 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  text-align: center;
  width: 90vw;
  max-width: 740px;
  max-height: 80vh;
}
