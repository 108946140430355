@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-Bold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-ExtraBold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-ExtraLight.ttf')
    format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.paragonone.com/public-app/fonts/Inter/Inter-Thin.ttf')
    format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-BlackItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-Bold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-BoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-ExtraBold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-Italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-LightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-MediumItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceOne-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-Bold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-BlackItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-BoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-ExtraBold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-Italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Sans ExBold';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-LightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-MediumItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceSans-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-BlackItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-Bold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-ExtraBold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-BoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-Italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-LightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-MediumItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://cdn.paragonone.com/public-app/fonts/OpenSauceSans/OpenSauceTwo-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}
