/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_14_101 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.clt_55_18 {
  transform: rotate(180deg);
}

:global(.genClass46) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  max-height: 97vh;
  max-width: 732px;
  width: 100vw;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  & > button {
    width: 34px;
    height: 34px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 34px;

    &:hover {
      width: 5px;
    }
  }
}

.clt_63_435 {
  padding-top: 1px;

  & svg {
    fill: var(--default-background);
  }
}

.clt_63_396 {
  padding: 8px;
  width: fit-content;
  align-self: flex-end;
  gap: 8px;
}

.clt_60_0 {
  & :global(.genClass45) {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    & > div {
      padding: 24px 0px 0px 0px;
    }
  }
}
