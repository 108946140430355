/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_101_275 {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.clt_98_247 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-top: 8px;

  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 880px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.clt_101_318 {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.clt_99_557 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-top: 8px;

  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 880px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.clt_98_27 {
  max-width: 1170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 16px;
    background: var(--default-background-hint);
    border-radius: 16px;

    & > h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: -0.05em;
      color: var(--default-text-title);
    }

    @media only screen and (max-width: 880px) {
      padding: 16px;
    }
  }
}

.clt_v200_103353 {
  background: var(--default-background);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  @media only screen and (max-width: 880px) {
    padding: 24px 16px 56px 16px;
    row-gap: 24px;
  }
}

.clt_v200_99940 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
