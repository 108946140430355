/* Theme */
:root {
  --default-background: #ffffff;
  --default-background-hint: #f7f9fc;
  --default-background-disabled: #e8e8e8;
  --default-text-disabled: #bfbfbf;
  --default-text-title: #061237;
  --default-text-body: #67718e;
  --default-info: #0135ff;
  --default-negative: #ee4a4a;
}

/* Default styles */

h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: -0.05em;
}

h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.05em;
}

h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.03em;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.04em;
  white-space: break-spaces;
}

/* Tooltip */

.tooltipWrapper-label {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 4px;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: flex-start;
  color: rgb(89, 89, 89);
  font-family: 'Open Sauce Sans';
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  max-width: 400px;
  text-align: justify;
}

.tooltipWrapper-label > svg {
  padding-top: 1px;
}

.tooltipWrapper-label > span {
  flex: 1;
}

/* Loading */

.loadingSpinner {
  display: block;
  height: 25px;
  width: 25px;
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 4px rgba(0, 0, 0, 0) solid;
  border-radius: 50%;
  -webkit-animation: spin4 1s infinite linear;
  animation: spin4 1s infinite linear;
}

@-webkit-keyframes spin4 {
  from {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes spin4 {
  from {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.portfolio-container-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 135px;
}

.portfolio-container-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1550px;
}

@media only screen and (max-width: 840px) {
  .portfolio-container-outer {
    padding: 40px 24px;
  }
}

@media only screen and (max-width: 570px) {
  .portfolio-container-outer {
    padding: 32px 16px;
  }
}
