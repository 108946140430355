/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_72_95 {
  width: 40px;
  height: 40px;
  background: var(--default-background-hint);
}

.clt_72_94 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_72_97 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_72_98 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_72_96 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.clt_72_93 {
  width: 140px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  transform: rotate(-45deg);
  transform-origin: top left;
  height: fit-content;
}

.clt_72_92 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.clt_72_91 {
  width: 954px;
}

.clt_72_102 {
  width: 40px;
  height: 40px;
  background: var(--default-background-hint);
}

.clt_72_101 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_72_104 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_72_105 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_72_103 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.clt_72_100 {
  width: 140px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  transform: rotate(45deg);
  height: fit-content;
  transform-origin: top right;
}

.clt_72_99 {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.clt_72_90 {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: -1;
  bottom: 80px;
}

.clt_70_244 {
  color: var(--default-background);
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;
}

.clt_70_245 {
  max-height: 24px;
  width: auto;
}

.clt_v200_103180 {
  background: var(--default-background);
  border-radius: 16px;
  padding: 10px;
}

.clt_70_243 {
  padding: 24px;
  background: var(--default-text-title);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.clt_72_69 {
  text-align: justify;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
}

.clt_72_59 {
  background: var(--default-background-hint);
  padding: 24px;
}

.clt_70_242 {
  width: 100%;
  max-width: 774px;
}

.clt_70_236 {
  padding: 80px 40px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: var(--default-background);
}

.clt_84_10 {
  color: var(--default-text-title);
}

.clt_206_522 {
  color: var(--default-info);
  letter-spacing: -0.02em;
  font-weight: 500;
}

.clt_208_58 {
  color: var(--default-text-body);
  letter-spacing: -0.02em;
  font-weight: 500;
}

.clt_208_132 {
  color: var(--default-text-body);
  letter-spacing: -0.02em;
  font-weight: 500;
}

.clt_208_49 {
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > span + span {
    padding-left: 16px;
    border-left: 1px solid var(--default-text-body);
  }
}

.clt_208_66 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.clt_142_274 {
  & > * + * {
    margin-top: 8px;
  }

  & ul {
    list-style: disc;
  }
}

.clt_84_15 {
  & > h3 {
    margin-bottom: 8px;
  }
}

.clt_84_152 {
  color: #e03a3a !important;
}

.clt_84_82 {
  & > h3 {
    margin-bottom: 8px;
  }
}

.clt_84_155 {
  & > h3 {
    margin-bottom: 8px;
  }
}

.clt_84_0 {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 1170px;
  margin: auto;

  & {
    letter-spacing: -0.02em;
    text-align: justify;
    color: var(--default-text-body);
  }

  & p + p {
    margin-top: 8px;
  }

  & h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.05em;
    color: var(--default-info);

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.clt_84_334 {
  padding: 40px;
  background: var(--default-background-hint);
}

.clt_75_24 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 80%;
  height: 80%;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.clt_75_23 {
  border-radius: 50%;
  background: var(--default-background-hint);
  width: 100%;
  overflow: hidden;
  position: relative;

  &:before {
    padding-top: 100%;
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 0;
  }
}

.clt_74_2 {
  background: var(--default-text-body);
  padding: 50px 70px;
}

.clt_75_63 {
  text-align: center;
  letter-spacing: -0.04em;
  color: var(--default-text-title);
  margin-bottom: 16px;
}

.clt_75_387 {
  margin-right: 6px;
}

.clt_75_209 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: var(--default-text-title);
}

.clt_75_210 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
}

.clt_75_203 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 8px;
}

.clt_76_32 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--default-text-title);

  &::placeholder {
    color: var(--default-text-title);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: var(--default-text-title);
  }

  &::-ms-input-placeholder {
    color: var(--default-text-title);
  }
}

.clt_82_208 {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.clt_82_259 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
}

.clt_82_255 {
  display: flex;
  flex-direction: row;
  padding: 10px;
  column-gap: 10px;
  margin: 6px 0px 18px 0px;

  & > hr {
    margin: auto;
    flex: 1;
    border-top: 1px solid var(--default-text-disabled);
  }
}

.clt_82_343 {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
}

.clt_82_370 {
  all: unset;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  margin-bottom: 12px;
}

.clt_82_239 {
  display: flex;
  flex-direction: column;
}

.clt_89_110 {
  text-align: center;
  letter-spacing: -0.04em;
  color: var(--default-text-title);
}

.clt_89_160 {
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.clt_89_175 {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.clt_91_0 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--default-text-title);
  background: var(--default-background-hint);
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  font-family: 'Open Sauce Sans';
  font-style: normal;
  font-weight: 400;
  border: unset;
  resize: none;
  outline: none;

  &::placeholder {
    color: var(--default-text-disabled);
    opacity: 1;
    font-size: 12px;
  }

  &:-ms-input-placeholder {
    color: var(--default-text-disabled);
    font-size: 12px;
  }

  &::-ms-input-placeholder {
    color: var(--default-text-disabled);
    font-size: 12px;
  }
}

.clt_89_241 {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
}

.clt_89_240 {
  all: unset;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  margin-top: 12px;
}

.clt_89_176 {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.clt_93_29 {
  letter-spacing: -0.04em;
  color: var(--default-text-title);
}

.clt_93_30 {
  letter-spacing: -0.02em;
  color: var(--default-text-body);
}

.clt_93_31 {
  width: 500px;
  max-width: 65vw;
  margin-top: 16px;
}

.clt_93_28 {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 8px;
}

.clt_93_23 {
  padding: 40px 64px;
}

.clt_74_3 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;
}

.clt_74_1 {
  border-radius: 16px;
  border: 1px solid var(--default-background-hint);
  box-shadow: 0px 10px 32px rgba(103, 113, 142, 0.2);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  max-width: 1170px;

  & > div {
    flex: 1;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.clt_74_0 {
  padding: 80px 40px;
  display: flex;
  justify-content: center;
}

.clt_84_311 {
  text-align: center;
  letter-spacing: -0.04em;
  color: var(--default-text-title);
}

.clt_85_319 {
  cursor: pointer;
}

.clt_85_278 {
  color: var(--default-info);
  font-weight: 500;
}

.clt_85_300 {
  color: var(--default-info);
  font-weight: 500;
}

.clt_85_0 {
  max-width: 980px;
  margin: 24px auto 0px auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.clt_84_310 {
  padding: 0px 40px 58px 40px;
  min-height: 880px;
}

.clt_87_59 {
  width: 40px;
  height: 40px;
  background: var(--default-background-hint);
}

.clt_87_58 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_87_61 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_87_62 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_87_60 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.clt_87_57 {
  width: 140px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  transform: rotate(-45deg);
  transform-origin: left top;
  height: fit-content;
}

.clt_87_56 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.clt_87_55 {
  width: 954px;
}

.clt_87_66 {
  width: 40px;
  height: 40px;
  background: var(--default-background-hint);
}

.clt_87_65 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_87_68 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_87_69 {
  width: 60px;
  height: 60px;
  background: var(--default-background-hint);
}

.clt_87_67 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.clt_87_64 {
  width: 140px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  transform: rotate(+45deg);
  height: fit-content;
  transform-origin: right top;
}

.clt_87_63 {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.clt_87_54 {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: -1;
  top: 50%;
  transform: translate(0, +50%);
}

.clt_87_51 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: var(--default-text-title);
}

.clt_87_52 {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.05em;
  color: var(--default-text-body);
}

.clt_v200_103801 {
  margin: 8px 0px;
  box-shadow: 0px 1.17474px 4.69898px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.clt_99_420 {
  display: block;
  height: 24px;

  & svg {
    fill: var(--default-text-title);
  }
}

.clt_99_419 {
  all: unset;
  background: var(--default-background);
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  padding: 14px;
  cursor: pointer;
}

.clt_98_368 {
  align-items: center;
  text-align: center;

  & svg {
    width: 12px;
    height: 12px;
    fill: var(--default-info);
  }

  & > span {
    flex: auto;
  }
}

.clt_94_83 {
  display: block;
  height: 24px;

  & svg {
    fill: var(--default-text-title);
  }
}

.clt_94_11 {
  all: unset;
  background: var(--default-background);
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  padding: 14px;
  cursor: pointer;
}

.clt_v200_103764 {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--default-text-body);
}

.clt_94_107 {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  align-items: center;
}

.clt_94_10 {
  display: flex;
  flex-direction: row;
  column-gap: 18px;
}

.clt_87_70 {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 40px;
  max-width: 655px;
  align-items: center;
  margin: auto;
  text-align: center;
}

.clt_87_48 {
  position: relative;
  z-index: 1;
}

.clt_95_363 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--default-text-title);
  white-space: break-spaces;
}

.clt_95_365 {
  letter-spacing: -0.02em;
  color: var(--default-text-body);
  line-height: 28px;
}

.clt_96_7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  row-gap: 24px;
}

.clt_95_341 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: var(--default-background-hint);
}

.clt_v200_98866 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
