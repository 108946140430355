/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_82_0 {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  & > input {
    cursor: pointer;
    margin: 0;
    transform: scale(1.25);
    accent-color: var(--default-info);
  }
}

.clt_82_2 {
  &:global(.required::after) {
    content: '*';
    color: var(--default-negative);
  }
}

.clt_82_1 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--default-text-body);
  cursor: pointer;
}
