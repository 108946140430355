/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_28_76 {
  padding: 24px;
  position: relative;
  min-height: 68px;
  width: 100%;
  min-width: 68px;
  background: #ffffff;
  border-radius: 8px;
  overflow: auto;
}

.clt_v200_102781 {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 24px;
  cursor: pointer;
}
